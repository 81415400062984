import axios from 'axios'

export default {
  reportsForResource: (resourceType, resourceId) => {
    return axios
      .get('/api/super_admin/reports/accessible_by', {
        params: {
          access_type: resourceType,
          resource_id: resourceId
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  addResourceReport: payload => {
    return axios
      .post('/api/super_admin/reports', payload, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  deleteResourceReport: (resourceType, resourceBankId) => {
    return axios
      .delete('/api/super_admin/reports', {
        params: {
          access_type: resourceType,
          resource_id: resourceBankId
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
