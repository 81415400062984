<template>
  <PageCard
    pageName="Report Access"
    :iconClass="['cuis-spreadsheet', 'card-icon-font']"
  >
    <template slot="page_content">
      <div class="row">
        <div class="col-sm-8">
          <h5>View Report Access</h5>
        </div>
        <div class="col-sm-4 text-right">
          <router-link
            tag="button"
            :to="{ path: 'select_fi' }"
            class="btn btn-primary fw-btn mt-3"
          >
            Add New Report
          </router-link>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-2">
          Access Type:
        </div>
        <div class="col-sm-3 pl-0">
          <b-form-select
            v-model="accessType"
            :options="accessOptions"
            @input="accessTypeSelected()"
          >
            <template slot="first">
              <option :value="null" disabled>Please select</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-2">
          Select User/Group:
        </div>
        <div class="col-sm-5 pl-0">
          <Multiselect
            track-by="value"
            label="text"
            v-model="accessByResource"
            :allowEmpty="false"
            :showLabels="false"
            :options="accessByOptions"
            class="simple-select analytics-select page-card-select"
            @input="fetchReports"
          ></Multiselect>
        </div>
        <div class="col-sm-1 pl-0 d-flex align-self-center">
          <v-wait for="fetchingAccessByOptions">
            <template slot="waiting">
              <h6 class="mb-0">Loading ...</h6>
            </template>
          </v-wait>
        </div>
      </div>

      <div class="row mb-3 pt-3" v-if="accessByResource">
        <div class="col-sm-12">
          <v-wait for="fetchingReports">
            <template slot="waiting">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="15"
                ></content-placeholders-text>
              </content-placeholders>
            </template>

            <b-row class="mb-3 justify-content-end">
              <b-col cols="4">
                <b-input-group>
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-search"></i
                    ></span>
                  </div>
                  <b-form-input
                    type="text"
                    v-model="filter"
                    @keydown.native.stop
                    placeholder="Search"
                  ></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>

            <b-table
              :items="reports"
              :fields="fields"
              :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              show-empty
              stacked="md"
              striped
              small
            >
              <template v-slot:cell(resource_name)="data">
                {{ selectedResource.name }}
              </template>
              <template v-slot:cell(actions)="data">
                <b-button
                  variant="secondary"
                  size="sm"
                  @click="reportForDestorySelected(data.item)"
                >
                  Delete
                </b-button>
              </template>
            </b-table>
            <b-row class="pt-2" v-if="this.reports.length">
              <b-col md="12" class="d-flex justify-content-center">
                <b-pagination
                  :total-rows="this.reports.length"
                  :per-page="perPage"
                  v-model="currentPage"
                >
                </b-pagination>
              </b-col>
            </b-row>
          </v-wait>
        </div>
      </div>

      <b-modal
        ref="delete-confirmation"
        title="Delete Access"
        @ok="deleteResourceReport()"
      >
        Are you sure, you want to delete this access?
      </b-modal>
    </template>
  </PageCard>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import PageCard from '@/modules/core/components/layouts/PageCard'
import reportAPI from '../../api/reports'
import Multiselect from 'vue-multiselect'

export default {
  name: 'SelectReportFI',
  components: {
    PageCard,
    Multiselect
  },
  data() {
    return {
      accessType: null,
      selectedReport: null,
      selectedResource: null,
      accessByResource: null,
      currentPage: 1,
      perPage: 20,
      reports: [],
      accessOptions: [
        { value: 'User', text: 'User Level' },
        { value: 'Group', text: 'Group Level' }
      ],
      filter: null
    }
  },
  computed: {
    ...mapState('SuperAdmin', {
      users: state => state.users,
      groups: state => state.groups
    }),
    ...mapGetters('SuperAdmin', ['usersSelectList', 'groupsSelectList']),
    accessByUser() {
      return this.accessType === 'User'
    },
    accessByOptions() {
      if (!this.accessType) {
        return []
      }
      return this.accessByUser ? this.usersSelectList : this.groupsSelectList
    },
    fields() {
      return [
        { key: 'resource_name', label: this.accessType, sortable: true },
        { key: 'id', label: 'Bank ID', sortable: true },
        { key: 'name', label: 'Bank Name', sortable: true },
        { key: 'city', label: 'City', sortable: true },
        { key: 'state', label: 'State', sortable: true },
        { key: 'period_id', label: 'Period ID', sortable: true },
        { key: 'actions', label: 'Action' }
      ]
    }
  },
  methods: {
    ...mapActions('SuperAdmin', [
      'fetchAllUsers',
      'fetchAllGroups',
      'resetReportAccess'
    ]),
    async accessTypeSelected() {
      this.accessByResource = null
      this.$wait.start('fetchingAccessByOptions')
      await (this.accessByUser ? this.fetchAllUsers() : this.fetchAllGroups())
      this.$wait.end('fetchingAccessByOptions')
    },
    fetchReports() {
      if (!this.accessByResource) {
        return false
      }
      this.setSelectedResource()
      this.$wait.start('fetchingReports')
      reportAPI
        .reportsForResource(this.accessType, this.accessByResource.value)
        .then(res => {
          this.reports = res
        })
        .finally(() => {
          this.$wait.end('fetchingReports')
        })
    },
    setSelectedResource() {
      let resourceList = this.accessByUser ? this.users : this.groups
      this.selectedResource = resourceList.find(
        resource => resource.id === this.accessByResource.value
      )
    },
    reportForDestorySelected(report) {
      this.selectedReport = report
      this.$refs['delete-confirmation'].show()
    },
    deleteResourceReport() {
      reportAPI
        .deleteResourceReport(
          this.accessType,
          this.selectedReport.resource_bank_id
        )
        .then(() => {
          let reportIndex = this.reports.findIndex(
            report =>
              report.resource_bank_id === this.selectedReport.resource_bank_id
          )
          this.reports.splice(reportIndex, 1)
          this.$toasted.show('Report access deleted', {
            icon: 'user-circle',
            type: 'success'
          })
        })
    }
  },
  beforeDestroy() {
    this.resetReportAccess()
  }
}
</script>

<style lang="scss" scoped></style>
